import React from 'react'
import NumberFormat from 'react-number-format'
import { Stack } from '@mui/material'

import { SummaryCard } from './SummaryCard'

type Input = {
  pnlPercent: number
  aum: number
  numberOfInvestors: number
  invested: number
  cashBalance: number
}
export const FundDashboardSummary = ({
  pnlPercent,
  aum,
  numberOfInvestors,
  invested,
  cashBalance,
}: Input): JSX.Element => {
  return <Stack
           spacing={3}
           overflow="auto"
           height={527}
           paddingRight={1.5}
         >
    <SummaryCard label="PROFIT/LOSS">
      <NumberFormat
        value={pnlPercent}
        thousandSeparator
        displayType={'text'}
        suffix={' %'}
        fixedDecimalScale
        decimalScale={2}
      />
    </SummaryCard>
    <SummaryCard label="NUMBER OF INVESTORS">
      <NumberFormat
        value={numberOfInvestors}
        thousandSeparator
        displayType={'text'}
      />
    </SummaryCard>
    <SummaryCard label="Assets Under Management">
      <NumberFormat
        value={aum}
        thousandSeparator
        displayType={'text'}
        prefix="$ "
        fixedDecimalScale
        decimalScale={2}
      />
    </SummaryCard>
    <SummaryCard label="INVESTED">
      <NumberFormat
        value={invested}
        thousandSeparator
        displayType={'text'}
        prefix="$ "
        fixedDecimalScale
        decimalScale={2}
      />
    </SummaryCard>
    <SummaryCard label="CASH BALANCE">
      <NumberFormat
        value={cashBalance}
        thousandSeparator
        displayType={'text'}
        prefix="$ "
        fixedDecimalScale
        decimalScale={2}
      />
    </SummaryCard>
  </Stack>
}
