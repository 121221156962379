import { createSlice } from '@reduxjs/toolkit'

import {
  loadTopFunds,
  createFund,
  deleteFund,
  exitFund,
  investFund,
  placeOrder,
} from '../api-actions'
import { Fund } from '../types'

type TopFundsState = { topFunds: Fund[] }
const initialState: TopFundsState = { topFunds: [] }

const slice = createSlice({
  name: 'funds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTopFunds.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data
      }
    })
    builder.addCase(placeOrder.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data.funds
      }
    })
    builder.addCase(investFund.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data.funds
      }
    })
    builder.addCase(exitFund.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data.funds
      }
    })
    builder.addCase(createFund.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data.funds
      }
    })
    builder.addCase(deleteFund.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.topFunds = action.payload.data.funds
      }
    })
  },
})

export const { reducer: fundsReducer } = slice
