import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Container
} from '@mui/material'
import Confetti from 'react-confetti'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from '../../state/store'
import { showCongratulations as showCongratulationsAction } from '../../state/api-actions'
import { setShowDrawer } from '../../state/slices/investor'


export const CongratDialog = (): JSX.Element | null => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  //investor
  const visitExplorePage = useSelector((state: RootState) => state.rewardTracking.progress.visitExplorePage)
  const copyFund = useSelector((state: RootState) => state.rewardTracking.progress.copyFund)
  const visitPortfolio = useSelector((state: RootState) => state.rewardTracking.progress.visitPortfolio)
  //manager
  const placeOrder = useSelector((state: RootState) => state.rewardTracking.progress.placeOrder)
  const visitFundDashboard = useSelector((state: RootState) => state.rewardTracking.progress.visitFundDashboard)
  const createFund = useSelector((state: RootState) => state.rewardTracking.progress.createFund)
  //submit
  const showCongratulations = useSelector((state: RootState) => state.rewardTracking.progress.showCongratulations)


  useEffect(() => {

    if (showCongratulations == undefined) return
    const finishedInvestor = visitExplorePage > 0 && copyFund > 0 && visitPortfolio > 0
    const finishedManager = placeOrder > 0 && visitFundDashboard > 0 && createFund > 0
    const shouldShow = showCongratulations == false && finishedInvestor && finishedManager
    if (shouldShow) {
      setOpen(true)

      dispatch(showCongratulationsAction(true))

    }

  }, [visitExplorePage, copyFund, visitPortfolio, placeOrder, visitFundDashboard, createFund, showCongratulations])
  const onClose = () => {
    setOpen(false)
    dispatch(setShowDrawer(true))
  }
  return (
    <Container component={open ? Confetti : Container}>
      <Dialog
        open={open}
        onClose={onClose}

        PaperProps={{
          sx: {
            boxShadow: '5.2px 5.2px 5.2px rgba(0, 0, 0, 0.25)',
            backgroundColor: '#0B2C4D',
            p: 4,
            borderRadius: '10px',
            textAlign: 'center',
          }
        }}
      >
        <DialogTitle color='primary'
          style={{ textAlign: 'center' }}
        >
          <h2> Congrats! You rock!</h2>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText color='white'>
            You have completed all the basic tasks in this demo.
          </DialogContentText>
          <Box pt={2} />
          <DialogContentText color='white'>
            Therefore you will be able to submit your entry to get a chance of getting a basic reward.
          </DialogContentText>
          <Box pt={2} />
          <DialogContentText color='white'>
            Please note that your journey is not quite end yet, keep exploring and you might be surprised
            what reward you would stump upon!
          </DialogContentText>
        </DialogContent>
      </Dialog >
    </Container>
  )
}
