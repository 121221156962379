import amplitude from 'amplitude-js'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  investFund,
  exitFund,
  createFund,
  deleteFund,
  placeOrder,
  loadUserInvestor,
  loadManagerDashboard,
  loadExplorePage,
  loadPortfolio,
  resetState,
} from '../api-actions'

const events = {
  investFund: 'invest fund',
  exitFund: 'exit fund',
  createFund: 'create fund',
  deleteFund: 'delete fund',
  placeOrder: 'place order',
  submitBasicReward: 'submit basic reward',
  visitFundDashboard: 'visit fund dashboard',
  visitExplorePage: 'visit explore page',
  visitPortfolio: 'visit portfolio',
  resetState: 'reset state',
}

type AnalyticsState = {
  isBasicRewardSubmitted: boolean
  source: string
  userId?: string
}
type BasicRewardArgs = {
  walletAddress: string
  twitterId: string
  telegramId: string
}

const sendFbPixelEvent = (eventName: 'Lead' | 'CompleteRegistration') => {
  // eslint-disable-next-line
  (window as any).fbq('track', eventName)
}

const amplitudeKey = '5b311bb401471f5264ada45fc338772f'
const client = amplitude.getInstance()
client.init(amplitudeKey)
client.setVersionName('demoV2')

const sourceParam = new URLSearchParams(window.location.search).get('source')
const initialState: AnalyticsState = {
  isBasicRewardSubmitted: false,
  source: sourceParam || 'organic',
}

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    submitBasicReward: (state: AnalyticsState, action: PayloadAction<BasicRewardArgs>) => {
      if (!state.isBasicRewardSubmitted) {
        const data = action.payload
        state.isBasicRewardSubmitted = true
        client.logEvent(events.submitBasicReward, {
          ...data,
          source: state.source,
        })
        sendFbPixelEvent('CompleteRegistration')
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserInvestor.fulfilled, (state, action) => {
      try {
        if (!state.userId || state.userId !== action.payload.data?.userId) {
          client.setUserId(action.payload.data?.userId ?? null)
          state.userId = action.payload.data?.userId
        }
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(investFund.fulfilled, (state) => {
      try {
        client.logEvent(events.investFund, { source: state.source })
        sendFbPixelEvent('Lead')
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(exitFund.fulfilled, (state) => {
      try {
        client.logEvent(events.exitFund, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(createFund.fulfilled, (state) => {
      try {
        client.logEvent(events.createFund, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(deleteFund.fulfilled, (state) => {
      try {
        client.logEvent(events.deleteFund, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(placeOrder.fulfilled, (state) => {
      try {
        client.logEvent(events.placeOrder, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(loadManagerDashboard.fulfilled, (state) => {
      try {
        client.logEvent(events.visitFundDashboard, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(loadPortfolio.fulfilled, (state) => {
      try {
        client.logEvent(events.visitPortfolio, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(loadExplorePage.fulfilled, (state) => {
      try {
        client.logEvent(events.visitExplorePage, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
    builder.addCase(resetState.fulfilled, (state) => {
      try {
        client.logEvent(events.resetState, { source: state.source })
      } catch (e) {
        console.log(e)
      }
    })
  },
})


export const { submitBasicReward } = slice.actions
export const { reducer: analyticsReducer } = slice
