// import { ThemeOptions } from '@mui/material'
import { createTheme } from '@mui/material/styles'
export const dark = createTheme({
  palette: {
    primary: {
      main: '#F07645',
    },
    secondary: {
      main: '#244E8E',
      dark: '#01203D',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.46)',
    },
    success: {
      main: '#5CC953',
    },
    background: {
      default: '#131722',
      paper: '#02080e',
    },
    divider: '#ffffff',
  },
  typography: {
    allVariants: {
      color: 'white',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#01203D',
          borderRadius: '13px',
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          backgroundColor: '#C4C4C4',

        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '85px',
          height: '85px',
          border: '1.02px solid #FFFFFF',
          boxSizing: 'border-box',
          background: '#001529',
          color: '#ffffff',
        },
      },
    },
  },
})
